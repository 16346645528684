
import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import RecycleBinItem from '@/components/material-select/recycle-bin.vue'
import RecycleBinItemOpticalField from '@/components/material-select/recycle-bin-optical-field.vue'
@Component({
  components: {
    RecycleBinItem,
    RecycleBinItemOpticalField,
  },
})
export default class Material extends Vue {
  materialType = ''
  activeName = 'image'
  paneLists = [
    {
      label: '图片',
      name: 'image',
      type: 'image',
    },
    {
      label: '视频',
      name: 'video',
      type: 'video',
    },
    {
      label: '交互',
      name: 'interaction',
      type: 'interaction',
    },
    {
      label: '光场',
      name: 'opticalField',
      type: 'opticalField',
    },
  ]

  pager = new RequestPaging()

  handleClick(tab: any) {
    // console.log(tab.name)
    this.materialType = tab.name == 'opticalField' ? '1' : '0'
  }
}
